import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/data/store";
import {
  selectAllowLateResubmission,
  selectHasAutoSubmission,
  selectIsAssignment,
  selectIsExam,
  selectIsLiveExam,
  selectIsTimedAssignment,
  selectIsWindowExam,
} from "@/features/assignment";
import {
  selectActiveReadingDate,
  selectActiveWritingDate,
  selectExamEndDatePassed,
  selectExamLateSubmissionDatePassed,
  selectExamStartDatePassed,
  selectFinalDatePassed,
  selectTimedWorkNotStarted,
  selectWorkStartDatePassed,
  selectWritingDatePassed,
} from "@/features/timeline";
import { getCurrentDate } from "@/utils/datetime";

import { ConnectionStatus } from "./types";

/** Select the `authority` slice state. */
const selectAuthoritySlice = (state: RootState) => state.authority;

/** Select current set saving mechanism for classic Cadmus. */
export const selectSavingMechanism = (state: RootState) => {
  return state.authority.savingMechanism;
};

/** Select if saves are in flight. */
export const selectIsSavingInFlight = (state: RootState) => {
  return state.authority.savesInFlight.length > 0;
};

/** Select the loaded work ID. */
export const selectWorkId = (state: RootState) => state.authority.workId;

/** Select if the work session should be locked. */
export const selectSessionLock = (state: RootState) =>
  state.authority.sessionLock;

/** Select if the network connection is intact. */
export const selectIsConnected = createSelector(
  selectAuthoritySlice,
  (authority) => authority.connectionStatus == ConnectionStatus.CONNECTED
);

/** Select if the work has an existing final submission. */
export const selectHasFinal = createSelector(
  selectAuthoritySlice,
  (authority) => authority.hasFinal
);

/** Select if the work has an existing draft submission. */
export const selectHasDraft = createSelector(
  selectAuthoritySlice,
  (authority) => authority.hasDraft
);

const selectSaveError = createSelector(
  selectAuthoritySlice,
  (authority) => authority.saveError
);

/** Select if the saving process has an error. */
export const selectSaveNetworkError = createSelector(
  selectAuthoritySlice,
  (authority) =>
    authority.saveError?.kind === "network" ? authority.saveError : null
);

/** Select if the submission preview page should be open. */
export const selectShowSubmitPreview = createSelector(
  selectAuthoritySlice,
  (authority) => authority.submitPreview
);

/** Select if the submission prompt modal should be shown. */
export const selectShowSubmitPrompt = createSelector(
  selectAuthoritySlice,
  (authority) => authority.submitPromptOpened !== null
);

export const selectSubmitDate = createSelector(
  selectAuthoritySlice,
  (authority) => {
    if (authority.submitPromptOpened !== null) {
      return authority.submitPromptOpened;
    }
    return getCurrentDate().toISOString();
  }
);

export const selectHasAcceptedSubmissionDeclarations = createSelector(
  selectAuthoritySlice,
  ({ hasAcceptedSubmissionDeclarations }) => hasAcceptedSubmissionDeclarations
);

/** Select if a submission request is in-flight. */
export const selectIsSubmitting = createSelector(
  selectAuthoritySlice,
  (authority) => authority.submitLoading
);

/** Select if the submission attempts are failing with an error. */
export const selectSubmitError = createSelector(
  selectAuthoritySlice,
  (authority) => authority.submitError
);

export const selectSubmitNetworkError = createSelector(
  selectSubmitError,
  (error) => (error?.kind === "network" ? error : null)
);

export const selectSubmitValidationError = createSelector(
  selectSubmitError,
  (error) => (error?.kind === "validation" ? error : null)
);

/**
 * Select if the exam is not yet open.
 *
 * Returns false if the assignment is not an exam or if the open date has not
 * passed.
 */
export const selectExamNotOpen = createSelector(
  selectIsExam,
  selectExamStartDatePassed,
  (isExam, examStarted) => isExam && !examStarted
);

/** Select record of all latest local snapshots for answers. */
export const selectSnapshots = createSelector(
  selectAuthoritySlice,
  (answer) => answer.snapshots
);

/** Arg selector to pass the Answer Block ID to children selectors. */
export const selectAnswerBlockId = (_state: RootState, answerBlockId: string) =>
  answerBlockId;

/**
 * Select latest snapshot for an Answer.
 */
export const selectAnswerSnapshot = createSelector(
  selectSnapshots,
  selectAnswerBlockId,
  (snapshots, answerBlockId) => snapshots[answerBlockId]
);

//////////////////////////////////////////////////////////////////////////////
// Compound selectors across other slices                                   //
//////////////////////////////////////////////////////////////////////////////

/**
 * Selector for checking Cadmus submission rules for exams and assignments.
 */
export const selectCanSubmit = (state: RootState) => {
  // Cannot request submissions in error or locked states.
  if (selectSessionLock(state) || selectSaveError(state)) {
    return false;
  }

  // Conditions for Exams
  if (selectIsExam(state)) {
    // Cannot submit before the exam is open
    if (!selectExamStartDatePassed(state)) {
      return false;
    }

    if (selectTimedWorkNotStarted(state)) {
      return false;
    }

    // Cannot request submissions during reading time.
    if (selectActiveReadingDate(state) !== null) {
      return false;
    }

    // Cannot submit after the writing/end date IF you have a submission
    if (selectWritingDatePassed(state) && !selectCanSubmitLateExam(state)) {
      return false;
    }
  }

  // Conditions for Timed assignments
  if (selectIsTimedAssignment(state)) {
    // Cannot submit before the work start date
    if (!selectWorkStartDatePassed(state)) {
      return false;
    }

    // No late re-submissions if not allowed
    if (
      selectWritingDatePassed(state) &&
      !selectCanSubmitLateAssignment(state)
    ) {
      return false;
    }
  }

  // Conditions for assignments
  if (selectFinalDatePassed(state) && !selectCanSubmitLateAssignment(state)) {
    return false;
  }

  return true;
};

/**
 * Select if late exam submission can be made.
 */
export const selectCanSubmitLateExam = createSelector(
  selectIsExam,
  selectHasAutoSubmission,
  selectExamLateSubmissionDatePassed,
  selectHasFinal,
  (isExam, autoSubmission, lateSubmissionDatePassed, hasFinal) => {
    return isExam && !autoSubmission && !lateSubmissionDatePassed && !hasFinal;
  }
);

/**
 * Select if a late assignment submission can be made.
 */
export const selectCanSubmitLateAssignment = createSelector(
  selectIsAssignment,
  selectAllowLateResubmission,
  selectHasFinal,
  (isAssn, allowLateResubmission, hasFinal) => {
    if (!isAssn) return false;
    if (hasFinal && allowLateResubmission) return true;
    if (hasFinal) return false;
    return true;
  }
);

/**
 * Select if the student can continue editing.
 */
export const selectCanUnsubmit = (state: RootState) => {
  if (selectIsLiveExam(state)) {
    if (selectWritingDatePassed(state)) {
      return false;
    }
  }

  if (selectIsWindowExam(state)) {
    if (selectWritingDatePassed(state)) {
      return false;
    }
  }

  if (selectIsTimedAssignment(state)) {
    if (selectWritingDatePassed(state) || selectFinalDatePassed(state)) {
      if (!selectCanSubmitLateAssignment(state)) {
        return false;
      }
    }
  }

  if (selectFinalDatePassed(state) && !selectCanSubmitLateAssignment(state)) {
    return false;
  }

  return true;
};

/**
 * Select if the student can currently edit their work editors.
 */
export const selectCanEdit = (state: RootState) => {
  if (
    selectSessionLock(state) ||
    selectShowSubmitPrompt(state) ||
    selectSubmitError(state) ||
    selectIsSubmitting(state)
  ) {
    return false;
  }

  if (selectIsExam(state)) {
    if (selectTimedWorkNotStarted(state)) {
      return false;
    }

    if (selectActiveReadingDate(state) !== null) {
      return false;
    }

    if (selectActiveWritingDate(state) !== null) {
      return true;
    }

    if (selectCanSubmitLateExam(state)) {
      return true;
    }

    return false;
  }

  if (selectIsTimedAssignment(state)) {
    if (!selectWorkStartDatePassed(state)) {
      return false;
    }

    if (
      selectWritingDatePassed(state) &&
      !selectCanSubmitLateAssignment(state)
    ) {
      return false;
    }
  }

  return true;
};

/**
 * Select if the Instructions should be locked or shown in Exams and Timed
 * Assignments.
 */
export const selectSheetLockFlags = createSelector(
  selectIsWindowExam,
  selectIsLiveExam,
  selectTimedWorkNotStarted,
  selectExamNotOpen,
  selectExamEndDatePassed,
  (
    isWindowExam,
    isLiveExam,
    timedWorkNotStarted,
    examNotOpen,
    examEndDatePassed
  ) => {
    // Flags to control sheet panel rendering
    let showExamLock = false;
    let showSheet = false;

    if (isWindowExam && timedWorkNotStarted) {
      if (examNotOpen || examEndDatePassed) {
        showSheet = false;
        showExamLock = false;
      } else {
        showSheet = false;
        showExamLock = true;
      }
    } else if (isLiveExam && examNotOpen) {
      showSheet = false;
      showExamLock = false;
    } else {
      showSheet = true;
      showExamLock = false;
    }
    return { showExamLock, showSheet };
  }
);
