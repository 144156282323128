import { AnswerSnapshot as SnapshotJSON } from "@vericus/cadmus-common";
import { Content, JSONContent } from "@vericus/cadmus-editor-prosemirror";

import {
  BlockSnapshotFragment,
  QuestionType,
  SaveFragment,
  SaveMetadataInput,
  SubmissionFragment,
} from "@/generated/graphql";
import { ClassicEditorName } from "@/stores/editor-store";

//////////////////////////////////////////////////////////////////////////////
// Types                                                                    //
//////////////////////////////////////////////////////////////////////////////

/**
 * Answer Block Snapshot state.
 */
export interface Snapshot {
  /** Snapshot version */
  version: number;
  /** Rich-Text ProseMirror contents. */
  answerDoc?: JSONContent;
  /** Multi-choice answers. */
  answerChoiceIds?: string[];
  /** Boolean-choice answer as true or false. */
  answerBoolean?: boolean;
  /** Short answer plain-text */
  answerShort?: string;
  /** Blanks answer */
  answerBlanks?: AnswerBlanks;
  /** Fields Answer */
  answerFields?: { [fieldIdentifier: string]: string[] };
  /** Whether the snapshot has incomplete field */
  isIncomplete?: boolean;
}

/**
 * Snapshot belonging to a specific answer block.
 */
export interface AnswerSnapshot {
  answerBlockId: string;
  snapshot: Snapshot;
}

export type AnswerBlanks = { [blankId: string]: string };

/**
 * Interface for the JSON document representing an Aphrodite save.
 *
 * Unlike the `SnapshotJSON` this document contains 3 editors: body, references,
 * and notes in one document.
 */
export interface SaveJSON {
  editor?: "prosemirror";
  body: {
    state: string;
    version?: number;
  };
  references: {
    state: string;
    version?: number;
  };
  notes: {
    state: string;
    version?: number;
  };
  // Other Metadata
  assessmentId: string;
  workId: string;
  sessionId: string | null;
  prevSaveId: string | null;
  prevVersionId: string | null;
}

//////////////////////////////////////////////////////////////////////////////
// Parsers                                                                  //
//////////////////////////////////////////////////////////////////////////////

/**
 * Parse BlockSnapshot contents into a well-known `Snapshot` shape.
 *
 * Returns a default value if `snapshot` is null.
 */
export function parseBlockSnapshot(snapshot: BlockSnapshotFragment): Snapshot {
  const parsed: Snapshot = {
    version: snapshot.version,
  };

  try {
    const raw: SnapshotJSON = JSON.parse(snapshot.content);
    if (raw["answer_choice_ids"] !== undefined) {
      parsed.answerChoiceIds = raw["answer_choice_ids"];
    }
    if (typeof raw["answer_boolean"] === "boolean") {
      parsed.answerBoolean = raw["answer_boolean"];
    }
    if (raw["answer_short"] !== undefined) {
      parsed.answerShort = raw["answer_short"];
    }
    if (
      raw["answer_doc"] !== undefined &&
      typeof raw["answer_doc"] === "object"
    ) {
      parsed.answerDoc = raw["answer_doc"];
    }
    if (raw["answer_blanks"] !== undefined) {
      parsed.answerBlanks = raw["answer_blanks"];
    }
    if (raw["answer_fields"] !== undefined) {
      parsed.answerFields = raw["answer_fields"];
    }
  } catch (err) {
    console.error(err);
    throw err;
  }

  return parsed;
}

/**
 * Parse a classic Work Save into a record of individual body, notes, references
 * Snapshots.
 */
export function parseSaveSnapshots(
  save: SaveFragment
): Record<ClassicEditorName, Snapshot> {
  const { body, references, notes } = JSON.parse(save.content) as SaveJSON;
  return {
    [ClassicEditorName.Body]: {
      answerDoc: JSON.parse(body.state),
      version: body.version ?? 0,
    },
    [ClassicEditorName.References]: {
      answerDoc: JSON.parse(references.state),
      version: references.version ?? 0,
    },
    [ClassicEditorName.Notes]: {
      answerDoc: JSON.parse(notes.state),
      version: notes.version ?? 0,
    },
  };
}

/**
 * Parse a Submission into a record of individual body, notes, references
 * snapshots.
 */
export function parseSubmissionSnapshots(
  submission: SubmissionFragment
): Record<ClassicEditorName, Snapshot> {
  if (submission.save) {
    return parseSaveSnapshots(submission.save);
  }

  const snapshots: Record<ClassicEditorName, Snapshot> = {
    body: { version: 0 },
    references: { version: 0 },
    notes: { version: 0 },
  };

  if (submission.blockSnapshots.length > 0) {
    const bodySnapshot = submission.blockSnapshots.find(
      (snapshot) => snapshot.blockName === "body"
    );
    const referencesSnapshot = submission.blockSnapshots.find(
      (snapshot) => snapshot.blockName === "references"
    );
    const bodyParsed = bodySnapshot && parseBlockSnapshot(bodySnapshot);
    const referencesParsed =
      referencesSnapshot && parseBlockSnapshot(referencesSnapshot);

    if (bodyParsed) {
      snapshots[ClassicEditorName.Body] = bodyParsed;
    }
    if (referencesParsed) {
      snapshots[ClassicEditorName.References] = referencesParsed;
    }
  }

  return snapshots;
}

//////////////////////////////////////////////////////////////////////////////
// Serialisation                                                            //
//////////////////////////////////////////////////////////////////////////////

/** Serialise snapshot state back to a JSON object. */
export function serialiseSnapshot(snapshot: Snapshot): SnapshotJSON {
  return {
    version: snapshot.version,
    answer_doc: snapshot.answerDoc ?? undefined,
    answer_choice_ids: snapshot.answerChoiceIds,
    answer_boolean: snapshot.answerBoolean,
    answer_short: snapshot.answerShort,
    answer_blanks: snapshot.answerBlanks,
    answer_fields: snapshot.answerFields,
  };
}

/** Create the WorkSaveDoc from classic editor snapshots. */
export function serialiseSnapshotsAsSave(
  snapshots: { body: Snapshot; notes: Snapshot; references: Snapshot },
  metadata: SaveMetadataInput
): SaveJSON {
  const body = {
    state: JSON.stringify(snapshots.body.answerDoc),
    version: snapshots.body.version,
  };
  const references = {
    state: JSON.stringify(snapshots.references.answerDoc),
    version: snapshots.references.version,
  };
  const notes = {
    state: JSON.stringify(snapshots.notes.answerDoc),
    version: snapshots.notes.version,
  };

  return {
    editor: "prosemirror",
    body,
    notes,
    references,
    ...metadata,
    sessionId: metadata.sessionId ?? null,
    prevSaveId: metadata.prevSaveId ?? null,
    prevVersionId: metadata.prevVersionId ?? null,
  };
}

//////////////////////////////////////////////////////////////////////////////
// Helpers                                                                  //
//////////////////////////////////////////////////////////////////////////////

/**
 * Predicate to check if the given snapshot for a question type is considered
 * incomplete or unanswered.
 *
 * @param questionType Type of question being answered
 * @param snapshot optional latest snapshot recorded as the answer
 * @returns `true` if the snapshot is considered incomplete, `false` if not,
 *   `null` if that decision cannot be made as the question type is unanswerable.
 */
export function isSnapshotEmpty(
  questionType: QuestionType,
  snapshot?: Snapshot
): boolean | null {
  if (snapshot?.isIncomplete) {
    return true;
  }

  const areFieldsEmpty = isAnswerFieldsEmpty(snapshot);

  switch (questionType) {
    case QuestionType.Short:
      return (
        areFieldsEmpty &&
        (snapshot === undefined ||
          snapshot.answerShort === undefined ||
          snapshot.answerShort === "")
      );
    case QuestionType.Truefalse:
      return (
        areFieldsEmpty &&
        (snapshot === undefined || snapshot.answerBoolean === undefined)
      );
    case QuestionType.Mcq:
      return (
        areFieldsEmpty &&
        (snapshot === undefined ||
          snapshot.answerChoiceIds === undefined ||
          snapshot.answerChoiceIds.length === 0)
      );
    case QuestionType.Blanks:
      return (
        areFieldsEmpty &&
        (snapshot === undefined ||
          snapshot.answerBlanks === undefined ||
          Object.keys(snapshot.answerBlanks).length === 0 ||
          Object.values(snapshot.answerBlanks).some((v) => v === ""))
      );
    case QuestionType.Matching:
    case QuestionType.Numerical:
      return areFieldsEmpty;
    case QuestionType.Extended:
      return (
        snapshot === undefined ||
        snapshot.answerDoc === undefined ||
        isEmptyAnswerDoc(snapshot.answerDoc)
      );
    case QuestionType.Hotspot:
      return areFieldsEmpty;
    default:
      return null;
  }
}

/**
 * Checks is answer fields are incomplete or unanswered.
 *
 * Returns true if even part of one field is incomplete.
 */
function isAnswerFieldsEmpty(snapshot?: Snapshot): boolean {
  return (
    snapshot === undefined ||
    snapshot.answerFields === undefined ||
    Object.keys(snapshot.answerFields).length === 0 ||
    Object.values(snapshot.answerFields).some(
      (values) =>
        values.length === 0 || values.some((v) => v === "" || v === null)
    )
  );
}

function isEmptyAnswerDoc(answerDoc: Content): boolean {
  if (!isJSONContent(answerDoc)) {
    return false;
  }
  const content = answerDoc.content ?? [];
  return content.length <= 1 && content[0]?.content === undefined;
}

function isJSONContent(answerDoc: Content): answerDoc is JSONContent {
  return (
    answerDoc !== null &&
    typeof answerDoc === "object" &&
    "content" in (answerDoc as JSONContent)
  );
}
