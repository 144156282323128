import { useId, useMemo } from "react";

import { Checkbox, ErrorMessage, LinkButton, Text } from "@vericus/cadmus-ui";

import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";

import * as styles from "./agreement-styles.css";

const SUBMISSION_DECLARATION_URL =
  "https://support.cadmus.io/students/submission-declaration";

export interface AcademicIntegrityAgreementProps {
  /**
   * Whether the student has agreed to the pledge.
   */
  isChecked?: boolean;

  /**
   * The assessment is an exam.
   */
  isExam?: boolean;

  /**
   * Use the short version of the pledge.
   */
  withShortenedPledge?: boolean;

  /**
   * Custom contentful Document to use as the custom declaration.
   */
  customDeclarationText?: string;

  /**
   * Callback to update the checked value of the agreement.
   */
  onUpdateAgreement: (hasAgreed: boolean) => void;
}

// Contentful React renderer for the declaration Document.
const customDeclarationTextRenderer: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => <>{children}</>,
    [INLINES.HYPERLINK]: (node, children) => {
      const link = node.data.uri;
      return (
        <LinkButton
          inline
          onClick={() => {
            const win = window.open(link, "_blank");
            if (win) {
              win.focus();
            }
          }}
        >
          {children}
        </LinkButton>
      );
    },
  },
};

/**
 * Allow students to access and agree, or disagree, to the academic integrity
 * pledge.
 */
export const AcademicIntegrityAgreement = ({
  isChecked,
  isExam,
  withShortenedPledge,
  customDeclarationText,
  onUpdateAgreement,
}: AcademicIntegrityAgreementProps) => {
  const pledgeId = useId(); // for a11y

  const customDeclaration = useMemo(() => {
    if (customDeclarationText) {
      try {
        const doc: Document = JSON.parse(customDeclarationText);
        return documentToReactComponents(doc, customDeclarationTextRenderer);
      } catch (error) {
        console.error(error);
        return null;
      }
    } else {
      return null;
    }
  }, [customDeclarationText]);

  return (
    <div
      className={styles.container}
      data-testid="academic-integrity-agreement"
    >
      <div className={styles.agreementHeader}>
        <Text kind="label" textAlign="left" inline>
          Academic Integrity Agreement
        </Text>
        <span className={styles.divider} />
      </div>
      <div
        className={
          !isChecked
            ? styles.agreementContainerWithError
            : styles.agreementContainer
        }
      >
        <Checkbox
          aria-labelledby={pledgeId}
          checked={isChecked}
          className={styles.agreementCheckbox}
          onChange={(e) => {
            onUpdateAgreement(e.target.checked);
          }}
        >
          {customDeclaration ? (
            <Text id={pledgeId} kind="bodySm" style={{ marginTop: 2 }}>
              <>{customDeclaration}</>
            </Text>
          ) : (
            <Text id={pledgeId} kind="bodySm" style={{ marginTop: 2 }}>
              {withShortenedPledge ? (
                <>I declare this work is my own, as per the </>
              ) : (
                <>
                  By completing and submitting this{" "}
                  {isExam ? "exam" : "assignment"}, I confirm that all work here
                  is my own, in adherence to the{" "}
                </>
              )}
              <LinkButton
                inline
                onClick={() => {
                  const win = window.open(SUBMISSION_DECLARATION_URL, "_blank");
                  if (win) {
                    win.focus();
                  }
                }}
              >
                submission declaration
              </LinkButton>
            </Text>
          )}
        </Checkbox>
      </div>
      {!isChecked && (
        <ErrorMessage fullWidth position="relative">
          You must review and accept the submission declaration in order to
          submit
        </ErrorMessage>
      )}
    </div>
  );
};
