import { useId } from "react";

import { Checkbox, ErrorMessage, Text } from "@vericus/cadmus-ui";

import * as styles from "./agreement-styles.css";

export interface GenerativeAiAgreementProps {
  /**
   * Whether the student has agreed to the pledge.
   */
  isChecked?: boolean;

  /**
   * The assessment is an exam.
   */
  isExam?: boolean;

  /**
   * Callback to update the checked value of the agreement.
   */
  onUpdateAgreement: (hasAgreed: boolean) => void;
}

/**
 * Allow students to access and agree, or disagree, to the generative AI
 * pledge.
 */
export const GenerativeAiAgreement = ({
  isChecked,
  isExam,
  onUpdateAgreement,
}: GenerativeAiAgreementProps) => {
  const pledgeId = useId(); // for a11y

  return (
    <div className={styles.container} data-testid="generative-ai-agreement">
      <div className={styles.agreementHeader}>
        <Text kind="label" textAlign="center">
          Generative AI Agreement
        </Text>
        <span className={styles.divider} />
      </div>
      <div
        className={
          !isChecked
            ? styles.agreementContainerWithError
            : styles.agreementContainer
        }
      >
        <Text kind="bodySm">
          The University acknowledges that Generative Artificial Intelligence
          (AI) tools are an important facet of contemporary life. Their use in
          assessments is considered in line with students’ development of the
          skills and knowledge which demonstrate the learning outcomes and
          underpin study and career success. Ensure that you adhere to the
          instructions for the use of Generative AI in this{" "}
          {isExam ? "exam" : "assignment"}. If in doubt, seek clarification.
        </Text>
        <Checkbox
          className={styles.agreementCheckbox}
          aria-labelledby={pledgeId}
          checked={isChecked}
          onChange={(e) => {
            onUpdateAgreement(e.target.checked);
          }}
        >
          <Text id={pledgeId} kind="bodySm" style={{ marginTop: 2 }}>
            I declare that in completing this {isExam ? "exam" : "assignment"} I
            have adhered to the instructions for use and acknowledgement of
            Generative AI outlined in this {isExam ? "exam" : "assignment"}.
          </Text>
        </Checkbox>
      </div>
      {!isChecked && (
        <ErrorMessage fullWidth position="relative">
          You must review and accept the submission declaration in order to
          submit
        </ErrorMessage>
      )}
    </div>
  );
};
