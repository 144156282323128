import { styled } from "styled-components";

import { colors, Text } from "@vericus/cadmus-ui";

export const QuestionWrapper = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 45px;
`;

interface CanvasWrapperProps {
  isHoveringPin: boolean;
  showPin: boolean;
  hasWiderImage: boolean;
}

export const CanvasWrapper = styled.div<CanvasWrapperProps>`
  display: flex;
  justify-content: center;
  border: 1px solid ${colors.navy300};
  border-radius: 8px;
  width: 100%;
  height: ${({ hasWiderImage }) =>
    hasWiderImage ? "100%" : "calc(max(50vh, 400px))"};
  width: ${({ hasWiderImage }) => (hasWiderImage ? "100%" : "max-content")};
  margin: 1rem auto;
  canvas {
    cursor: ${(props) =>
      props.showPin ? `none` : props.isHoveringPin ? "pointer" : "default"};
  }
`;

export const OptionDividerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;
`;

export const OptionsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
  border: 1px dashed ${colors.navy300};
  border-radius: 8px;
`;

export const OptionButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  gap: 0.5rem;
  margin: 0.8rem 0;
  margin-left: 0.8rem;
`;

export const OptionButtonText = styled(Text)<{ selected: boolean }>`
  background-color: ${colors.bgrey300};
  border-radius: 8px;
  border: 1px solid ${colors.bgrey400};
  padding: 3px 10px;
  border-color: ${(props) =>
    props.selected ? colors.primary : colors.bgrey400};
`;

export const TipContainer = styled.div`
  width: 100%;
  border-left: 4px solid ${colors.primary};
  background-color: ${colors.bgrey300};
  p {
    padding: 12px 20px;
  }
`;
