import { useEffect } from "react";
import { styled } from "styled-components";

import { Modal, Spacer, Text } from "@vericus/cadmus-ui";

import { create, useModal } from "@ebay/nice-modal-react";

import { useAppDispatch, useAppSelector } from "@/data/hooks";
import { selectAllowGenerativeAi, selectIsExam } from "@/features/assignment";
import {
  acceptSubmissionDeclaration,
  declineSubmissionDeclaration,
  selectHasAcceptedSubmissionDeclarations,
} from "@/features/authority";

import {
  SubmissionDeclaration,
  SubmissionDeclarationProps,
} from "./SubmissionDeclaration";

export interface SubmissionDeclarationModalProps
  extends SubmissionDeclarationProps {
  open: boolean;
  onExitComplete?: VoidFunction;
}

export const SubmissionDeclarationModal = (
  props: SubmissionDeclarationModalProps
) => {
  return (
    <Modal.Root open={props.open}>
      <Modal.Content
        withCloseButton={false}
        width={636}
        onExitComplete={props.onExitComplete}
      >
        <Container>
          <Text kind="headingOne" textAlign="center">
            Unable to submit
          </Text>
          <Spacer spacing={48} />

          <SubmissionDeclaration {...props} />
        </Container>
      </Modal.Content>
    </Modal.Root>
  );
};

export interface ConnectedSubmissionDeclarationModalProps {
  onAgree: VoidFunction;
}

/**
 * A blocking modal that will only be shown if the student has not agreeded
 * to the required pledges in order to submit a timed assessment when the time is up
 */
export const ConnectedSubmissionDeclarationModal =
  create<ConnectedSubmissionDeclarationModalProps>(({ onAgree }) => {
    const isExam = useAppSelector(selectIsExam);

    const requireGenAiAgreement =
      useAppSelector(selectAllowGenerativeAi) !== null;

    const hasAcceptedSubmissionDeclarations = useAppSelector(
      selectHasAcceptedSubmissionDeclarations
    );

    useEffect(() => {
      if (
        hasAcceptedSubmissionDeclarations.academicIntegrity &&
        (requireGenAiAgreement
          ? hasAcceptedSubmissionDeclarations.generativeAi
          : true)
      ) {
        onAgree();
      }
    }, [hasAcceptedSubmissionDeclarations, onAgree, requireGenAiAgreement]);

    const modal = useModal();

    const dispatch = useAppDispatch();

    return (
      <SubmissionDeclarationModal
        open={modal.visible}
        onExitComplete={modal.remove}
        isExam={isExam}
        hasCheckedPledges={hasAcceptedSubmissionDeclarations}
        onUpdateSubmissionDeclaration={(submissionDeclaration, isAccepted) => {
          if (isAccepted)
            return dispatch(
              acceptSubmissionDeclaration({ submissionDeclaration })
            );
          return dispatch(
            declineSubmissionDeclaration({ submissionDeclaration })
          );
        }}
        requireGenAiAgreement={requireGenAiAgreement}
      />
    );
  });

const Container = styled.div`
  padding: 76px 88px;
`;
