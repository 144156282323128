
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "QuestionInteraction": [
      "ChoiceInteraction",
      "ExtendedInteraction",
      "MatchInteraction",
      "PositionObjectInteraction",
      "TextEntryInteraction"
    ],
    "QuestionResponse": [
      "BlanksResponse",
      "BooleanResponse",
      "EditorResponse",
      "MultiResponse",
      "ShortResponse"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "QuestionInteraction": [
      "ChoiceInteraction",
      "ExtendedInteraction",
      "MatchInteraction",
      "PositionObjectInteraction",
      "TextEntryInteraction"
    ],
    "QuestionResponse": [
      "BlanksResponse",
      "BooleanResponse",
      "EditorResponse",
      "MultiResponse",
      "ShortResponse"
    ]
  }
};
      export default result;
    