import { styled } from "styled-components";

import { colors } from "@vericus/cadmus-ui";

export const QuestionWrapper = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 45px;
`;

export const CanvasWrapper = styled.div<{ hasWiderImage: boolean }>`
  display: flex;
  justify-content: center;
  border: 1px solid ${colors.navy300};
  border-radius: 8px;
  height: ${({ hasWiderImage }) =>
    hasWiderImage ? "100%" : "calc(max(50vh, 400px))"};
  width: ${({ hasWiderImage }) => (hasWiderImage ? "100%" : "max-content")};
  margin: 1rem auto;
`;

export const FormWrapper = styled.div`
  width: 790px;
`;
