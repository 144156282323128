import { Spacer } from "@vericus/cadmus-ui";

import { AuthorityState } from "@/features/authority";
import { useInstitution } from "@/graphql/hooks";

import { AcademicIntegrityAgreement } from "./AcademicIntegrityAgreement";
import { GenerativeAiAgreement } from "./GenerativeAiAgreement";

export interface SubmissionDeclarationProps {
  /**
   * The assessment is an exam.
   */
  isExam?: boolean;
  /**
   * Use the short version of the pledge.
   */
  withShortenedPledge?: boolean;
  /**
   * Whether the student has agreed to the submission pledges.
   */
  hasCheckedPledges: AuthorityState["hasAcceptedSubmissionDeclarations"];
  /**
   * Callback to update the checked value of the a submission declaration agreement.
   */
  onUpdateSubmissionDeclaration: (
    declarationType: keyof AuthorityState["hasAcceptedSubmissionDeclarations"],
    hasAgreed: boolean
  ) => void;
  /**
   * Whether the generative AI agreement is required.
   */
  requireGenAiAgreement?: boolean;
}

/**
 * Provides the academic integrity and generative AI agreements for the student
 * to agree to before their work can be submitted.
 * Academic Integrity agreement is always required while Generative AI agreement is
 * optional, as per the requirement of the tenant.
 * The Academic Integrity Agreement will use custom institution declaration if available in
 * the graphql cache.
 */
export function SubmissionDeclaration(props: SubmissionDeclarationProps) {
  const { data } = useInstitution();
  const customAcademicIntegrityDeclarationText =
    data.customDeclarationText ?? undefined;

  return (
    <>
      <AcademicIntegrityAgreement
        customDeclarationText={customAcademicIntegrityDeclarationText}
        isChecked={props.hasCheckedPledges.academicIntegrity}
        onUpdateAgreement={(hasAccepted) =>
          props.onUpdateSubmissionDeclaration("academicIntegrity", hasAccepted)
        }
        {...props}
      />
      <Spacer spacing={20} />
      {props.requireGenAiAgreement && (
        <>
          <GenerativeAiAgreement
            isChecked={props.hasCheckedPledges.generativeAi}
            onUpdateAgreement={(hasAccepted) =>
              props.onUpdateSubmissionDeclaration("generativeAi", hasAccepted)
            }
            {...props}
          />
          <Spacer spacing={20} />
        </>
      )}
    </>
  );
}
